@import "common.less";
@import "client.less";
@import "task.less";
@import "contact.less";
@import "employee.less";
@import "chat.less";
@import "bill.less";
@import "company.less";

.sidebar {
  text-align: center;
  background: #fff;
  position: sticky;
  overflow: auto;
  height: 100vh;
  top: 0;
  left: 0;
  .btn-logout {
    background-color: @primary-color;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px;
    width: 100%;
  }
  .hammenu {
    text-align: end;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .name {
    color: @text-color;
    font-size: 18px;
    .first {
      font-weight: 500;
    }
  }
  .position {
    color: #b1b7bd;
  }
  .side-nav {
    background-color: #e1f6f9;
    list-style: none;
    margin: 5px;
    border-radius: 10px;
    color: #b1b7bd;
    padding: 5px;
    text-align: start;

    li {
      display: flex;
      align-items: center;
      &.ant-menu-item-selected {
        color: @text-color-secondary;
        background-color: @primary-color;
        border-radius: 10px;
      }
      &:hover {
        background-color: @primary-color;
        color: @text-color-secondary;
        border-radius: 10px;
      }
    }
    li {
      &.ant-menu-submenu-selected {
        color: @text-color-secondary !important;
        background-color: @primary-color !important;
        border-radius: 0.5em !important;
      }

      .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        &:hover {
          background-color: @primary-color;
          color: @text-color-secondary !important;
          border-radius: 0.5em;
        }
      }
    }
  }
}
.main-content {
  background-color: #f2f6fc;
  padding: 10px;
}

#main {
  .d-content {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    border-radius: 2em;
  }
  .online-sec {
    height: 400px;
    background-color: #fff;
    margin-top: 1em;
    width: 100%;
    border-radius: 2em;
  }
  .o-title {
    font-size: 20px;
    font-weight: 700;
  }
  .online-cont {
    margin-top: 2em;
  }
  .online-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
  .com-text {
    font-size: 16px;
    margin-bottom: 0px;
  }
  .com-text1 {
    font-size: 14px;
    font-weight: 400;
  }
  .qwy {
    display: flex;
  }
  .qwe {
    margin-left: 1em;
  }
  .row-space {
    margin-top: 2em;
  }
  .his-text {
    font-size: 18px;
    padding: 5px 10px;
    font-weight: 400;
  }
  .his-text:hover {
    background-color: @primary-color;
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }
  .ant-progress-circle .ant-progress-text {
    color: black;
  }
}
.s-box {
  background-color: #f2f6fc;
  padding: 5px 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.s-icon {
  height: 23px;
}
.contact-search {
  background: #f2f6fc;
  border: none;
  color: black;
}
input.contact-search:focus {
  border: none;
  outline: none !important;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 70px;
  height: 70px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid @primary-color; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.small-spinner {
  width: 15px;
  height: 15px;
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid @primary-color;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.global-modal {
  float: right;
}

.dashboard-card {
  border: 1px solid #b1b7bd;
  border-radius: 2rem;
  padding: 1em;
}

.dashboard-card-2 {
  border: 1px solid #b1b7bd;
  border-radius: 2rem;
  padding: 1em;
}

.comment-section {
  height: 65vh;
  background-color: #f2f5f6;
  border-radius: 10px;
}

.right-align h3,
.right-align-message h4 {
  display: flex;
  justify-content: flex-end;
}

.right-align-message {
  display: block !important;
}

.right-align-message h4 {
  margin-right: 50px;
}

.feed-section {
  min-height: 50vh;
  box-shadow: 2px 7px 25px 15px rgb(0 0 0 / 5%);
  -webkit-box-shadow: 2px 7px 25px 15px rgb(0 0 0 / 5%);
  -moz-box-shadow: 2px 7px 25px 15px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border-radius: 2rem;
}

.objective-section {
  height: 30vh;
  margin-top: 10px;
  border-radius: 1rem;
}

.timeline-details {
  border: 1px solid #b1b7bd;
  border-radius: 2rem;
  padding: 1em;
}

.total-info {
  opacity: 0.8;
  font-weight: 700;
  text-align: center;
  font-size: 50px;
  margin-right: 10px;
}

.details-card {
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 280px;
  max-width: 281px;
  padding: 10px 12px;
  margin-bottom: 15px;
  transition: 0.7s;
  animation: ease-in;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 28px;
}
.emp-detail-card-container {
  overflow-x: scroll;
  display: flex;
}

.details-card:hover {
  transform: scale(1.07);
}

.details-card-inv {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 220px;
  padding: 10px 12px;
  margin-bottom: 15px;
  margin-left: 20px;
  transition: 0.7s;
  animation: ease-in;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 28px;
}

.details-card-inv:hover {
  transform: scale(1.07);
}

#inv-products,
#inv-product-category,
#inv-product-brand,
#inv-product-unit {
  padding: 2rem;
}

.animate-header {
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

@keyframes scale {
  100% {
    transform: scale(1);
  }
}

#animate-text {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

#animate-text:nth-child(1) {
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.counting-image {
  height: 40px;
  width: 40px;
}

.feed-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin-top: 25px;
}

.feed-text {
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgb(0 0 0 /20%);
  padding: 30px;
  margin: 20px;
  position: relative;
  border-radius: 10px;
  min-width: 300px;
  cursor: pointer;
}

.feed-text-detail {
  background-color: #fff;
  box-shadow: 0px 5px 20px 0px rgb(0 0 0 /20%);
  padding: 30px;
  position: relative;
  border-radius: 10px;
  min-width: 300px;
  margin-top: 25px;
}

.single-feed-detail {
  margin-top: 25px;
  margin-left: 15px;
}

.feed-detail-image {
  width: 150px;
  height: 150px;
}

.btn-btn-register {
  border-radius: 0.5em !important;
  display: flex !important;
  left: 85%;
  margin-bottom: 10px;
}

.comment-input {
  width: 510px !important;
}

.services-inv {
  border: 1px solid #b1b7bd;
  border-radius: 2rem;
  padding: 1em;
}

svg {
  cursor: pointer !important;
}

.text-text {
  width: 24rem !important;
  border-radius: 0.5em !important;
}

.temp-card {
  padding: 8px;
  border: 1px solid #b1b7bd;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.ant-modal .ant-modal-header {
  border-radius: 0px !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .feed-col {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .feed-responsive {
    flex-direction: column;
  }

  .comps-responsive {
    flex-direction: column;
  }

  .date-notify {
    font-size: 7px !important;
    margin-right: 10px !important;
    margin-left: 5px !important;
  }

  .badge-responsive {
    margin-bottom: 15px !important;
    margin-left: 15px !important;
  }

  .fiscal-table {
    overflow-x: auto !important;
    display: block !important;
    white-space: nowrap !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    min-width: 220%;
  }

  .global-modal {
    width: 45vw !important;
  }

  .notify-img {
    min-width: 27px !important;
    min-height: 27px !important;
  }

  .fit-res {
    width: 100% !important;
  }

  .profile-edit {
    margin-left: 80% !important;
  }

  .btn-btn-register {
    left: 70%;
  }

  #col-col {
    margin-left: 5px !important;
    margin-bottom: 15px !important;
  }

  .dashboard-card-2 {
    margin-bottom: 15px;
    width: 250%;
  }

  .dashboard-card {
    width: 175%;
  }

  .clinechart {
    width: 150% !important;
    margin-bottom: 10px;
  }

  .ant-btn-primary {
    margin-right: 25px !important;
  }
}

.loader-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.brandImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: 5px;
}

.task-tag {
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  margin-top: 4rem;
}
